import {
	DocumentData,
	getDocs,
	QueryDocumentSnapshot,
	QuerySnapshot,
	SnapshotOptions,
} from '@firebase/firestore';
import {
	CollectionReference,
	query,
	QueryConstraint,
} from 'firebase/firestore';
import intersectionBy from 'lodash/intersectionBy';
import isArray from 'lodash/isArray';

export function getGenericConverter<T>() {
	return {
		toFirestore(type: T): DocumentData {
			removeUndefined(type);
			return type;
		},

		fromFirestore(
			snapshot: QueryDocumentSnapshot,
			options: SnapshotOptions,
		): T {
			const data = snapshot.data(options);
			transformData(data);
			return data as T;
		},
	};
}

const transformData = (data: any) => {
	Object.keys(data).forEach(key => {
		if (data[key]?.toDate) {
			data[key] = data[key].toDate();
		} else if (isArray(data[key])) {
			for (const arrayData of data[key]) {
				transformData(arrayData);
			}
		}
	});
};

const removeUndefined = (data: any) => {
	if (!data) {
		return;
	}
	Object.keys(data).forEach(key => {
		if (data[key] === undefined) {
			delete data[key];
		} else if (isArray(data[key])) {
			for (const arrayData of data[key]) {
				removeUndefined(arrayData);
			}
		}
	});
};

export async function getMultipleConstraints<T>(
	collectionRef: CollectionReference<T>,
	filterOptions: QueryConstraint[][],
): Promise<QueryDocumentSnapshot<T>[]> {
	const collections: QuerySnapshot<T>[] = [];

	for (const constraint of filterOptions) {
		try {
			const querySnapshot = await getDocs(query(collectionRef, ...constraint));
			collections.push(querySnapshot);
		} catch (ex) {
			console.error('could not query', ex);
		}
	}
	const docs = collections.map(collection => collection.docs);
	return intersectionBy(...docs, 'id');
}
