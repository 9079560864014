import {
	IonButton,
	IonButtons,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonProgressBar,
	useIonToast,
} from '@ionic/react';
import { briefcaseOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import {
	getAcceptInvite,
	getDeclineInvite,
	useOrganizationUserInvite,
} from '../../database/invites';
import { UserInvitesProps } from './types';
import './inviteStyle.css';
import { useState } from 'react';

const UserInvites: React.FC<UserInvitesProps> = ({ subtext }) => {
	const { t } = useTranslation();
	const [invites] = useOrganizationUserInvite();
	const [working, setWorking] = useState(false);

	const [presentToast] = useIonToast();

	const acceptClick = async (id: string) => {
		setWorking(true);
		try {
			await getAcceptInvite()(id);
			presentToast(t('inviteAccepted'), 5000);
		} catch (ex) {
			presentToast(t('errorInviteResponse'), 5000);
		} finally {
			setWorking(false);
		}
	};
	const declineClick = async (id: string) => {
		setWorking(true);
		try {
			await getDeclineInvite()(id);
			presentToast(t('inviteDeclined'), 5000);
		} catch (ex) {
			presentToast(t('errorInviteResponse'), 5000);
		} finally {
			setWorking(false);
		}
	};

	const renderEmpty = () => {
		return (
			<div className="invite-empty-container">
				<div className="invite-empty-image-container">
					<IonIcon size="large" icon={briefcaseOutline} />
				</div>
				<div className="invite-empty-title">{t('noInvites')}</div>
				{subtext ? <div className="invite-empty-sub">{subtext}</div> : null}
			</div>
		);
	};

	return !invites || invites.empty ? (
		renderEmpty()
	) : (
		<>
			{working && <IonProgressBar type="indeterminate" />}
			<IonList>
				<IonListHeader>{t('acceptInvite')}</IonListHeader>
				{invites?.docs.map(doc => {
					const invite = doc.data();
					return (
						<IonItem key={doc.id}>
							<IonLabel text-wrap>{invite.organizationName}</IonLabel>
							<IonButtons slot="end">
								<IonButton
									disabled={working}
									onClick={() => declineClick(invite.id)}
									color="danger"
								>
									{t('decline')}
								</IonButton>
								<IonButton
									disabled={working}
									onClick={() => acceptClick(invite.id)}
								>
									{t('accept')}
								</IonButton>
							</IonButtons>
						</IonItem>
					);
				})}
			</IonList>
		</>
	);
};

export default UserInvites;
