import { PROJECTS_COLLECTION } from './Project';

export const MILESTONES_COLLECTION = 'milestones';

export const getMilestonesCollection = (projectId: string) =>
	`${PROJECTS_COLLECTION}/${projectId}/${MILESTONES_COLLECTION}`;

export interface Milestone {
	id: string;
	name: string;
	description?: string;
	checklists: MilestoneChecklist[];
}

export interface MilestoneChecklist {
	id: string;
	name: string;
	isCompleted: boolean;
	totalAmount: number;
	completedAmount: number;
}
