import { IonIcon, isPlatform } from '@ionic/react';
import Autocomplete, {
	AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { locationOutline } from 'ionicons/icons';
import throttle from 'lodash/throttle';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { GOOGLE_PLACES_KEY } from '../../api/maps';
import { GooglePlacesAutocompleteProps, PlaceResult, PlaceType } from './types';

function loadScript(src: string, position: HTMLElement | null, id: string) {
	if (!position) {
		return;
	}

	const script = document.createElement('script');
	script.setAttribute('async', '');
	script.setAttribute('id', id);
	script.src = src;
	position.appendChild(script);
}

const autocompleteService = { current: null };
const placeService = { current: null };

const GooglePlacesAutocomplete: React.FC<GooglePlacesAutocompleteProps> = ({
	onPlaceSelect,
	inputValue,
	onInputChange,
	attributions,
	inputClassName,
	className,
}) => {
	const { t } = useTranslation();
	const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
	const loaded = React.useRef(false);

	if (typeof window !== 'undefined' && !loaded.current) {
		if (!document.querySelector('#google-maps')) {
			loadScript(
				`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_KEY}&libraries=places`,
				document.querySelector('head'),
				'google-maps',
			);
		}

		loaded.current = true;
	}

	const fetch = React.useMemo(
		() =>
			throttle(
				(
					request: { input: string },
					callback: (results?: readonly PlaceType[]) => void,
				) => {
					(autocompleteService.current as any).getPlacePredictions(
						request,
						callback,
					);
				},
				200,
			),
		[],
	);

	const getInput = (params: AutocompleteRenderInputParams) => {
		const extraClass = isPlatform('ios') ? 'ml-4 pr-4' : '';
		return (
			<TextField
				className={`${className} ${extraClass}`}
				variant="standard"
				{...params}
				InputLabelProps={{ className: `p-2 ${inputClassName} `, shrink: true }}
				inputProps={{
					...params.inputProps,
					className: 'p-2 mt-2',
					autoComplete: 'new-password',
					form: {
						autocomplete: 'new-password',
					},
				}}
				label={t('address')}
				fullWidth
			/>
		);
	};

	React.useEffect(() => {
		let active = true;

		if (!autocompleteService.current && (window as any).google) {
			autocompleteService.current = new (
				window as any
			).google.maps.places.AutocompleteService();
		}

		if (!placeService.current && (window as any).google) {
			placeService.current = new (
				window as any
			).google.maps.places.PlacesService(attributions);
		}
		if (!placeService.current || !autocompleteService.current) {
			return undefined;
		}

		if (inputValue === '') {
			setOptions([]);
			return undefined;
		}

		fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
			if (active) {
				let newOptions: readonly PlaceType[] = [];

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [inputValue, fetch, attributions]);

	return (
		<Autocomplete
			id="google-map-demo"
			className="w-full"
			getOptionLabel={option =>
				typeof option === 'string' ? option : option.description
			}
			inputValue={inputValue}
			onInputChange={(event, text) => {
				if (event) {
					onInputChange(text);
				}
			}}
			filterOptions={x => x}
			options={options}
			autoComplete
			includeInputInList
			filterSelectedOptions
			onChange={(event: any, newValue: PlaceType | null) => {
				setOptions(newValue ? [newValue, ...options] : options);
				if (autocompleteService.current && newValue && newValue.place_id) {
					(placeService.current as any).getDetails(
						{ placeId: newValue.place_id },
						(result: PlaceResult) => {
							onPlaceSelect(result);
						},
					);
				}
			}}
			renderInput={params => getInput(params)}
			renderOption={(props, option) => {
				const matches =
					option.structured_formatting.main_text_matched_substrings;
				const parts = parse(
					option.structured_formatting.main_text,
					matches.map((match: any) => [
						match.offset,
						match.offset + match.length,
					]),
				);

				return (
					<li {...props}>
						<Grid container alignItems="center">
							<Grid item>
								<IonIcon className="mr-2" icon={locationOutline} />
							</Grid>
							<Grid item xs>
								{parts.map((part, index) => (
									<span
										key={index}
										style={{
											fontWeight: part.highlight ? 700 : 400,
										}}
									>
										{part.text}
									</span>
								))}
								<Typography variant="body2" color="text.secondary">
									{option.structured_formatting.secondary_text}
								</Typography>
							</Grid>
						</Grid>
					</li>
				);
			}}
		/>
	);
};

export default GooglePlacesAutocomplete;
