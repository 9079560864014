import { UseFormRegisterReturn } from 'react-hook-form';

export function ionRegister(register: UseFormRegisterReturn) {
	const {
		name,
		onBlur,
		onChange,
		ref,
		disabled,
		max,
		maxLength,
		min,
		minLength,
		pattern,
		required,
	} = register;
	return {
		name,
		ref,
		disabled,
		maxLength,
		minLength,
		pattern,
		required,
		min: min as string | undefined,
		max: max as string | undefined,
		onIonChange: onChange,
		onIonBlur: onBlur,
	};
}
