export const BOARD_COLLECTION = 'boards';

export interface KanbanBoard {
	id: string;
	organizationId: string;
	name: string;
	description?: string;
	cards: KanbanBoardCard[];
	templates?: KanbanBoardCard[];
}

export interface KanbanBoardCard {
	/**
	 * Unique id of the card.
	 */
	id: string;
	/**
	 * Name of the card.
	 */
	name: string;
	/**
	 * Descrition of the task.
	 */
	description: string;
	/**
	 * Side color of the card.
	 */
	color: string;
	/**
	 * Lables applied to the card;
	 */
	labels: KanbanLabel[];
}

export interface KanbanLabel {
	key: string;
	value: string;
	icon?: string;
}
