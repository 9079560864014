import { Contact } from './Contact';
import { FirebaseFile } from './FirebaseFile';
import { ProjectRole } from './OrganizationTeam';

export const PROJECTS_COLLECTION = 'projects';
export const PROJECT_TEAM_COLLECTION = 'team';
export const PROJECT_CONTACTS_COLLECTION = 'contacts';
export const PROJECT_BOOKMARKS_COLLECTION = 'bookmarks';

export const getProjectTeamCollectionPath = (projectId: string) => {
	return `${PROJECTS_COLLECTION}/${projectId}/${PROJECT_TEAM_COLLECTION}`;
};

export const getProjectBookmarkCollectionPath = (projectId: string) => {
	return `${PROJECTS_COLLECTION}/${projectId}/${PROJECT_BOOKMARKS_COLLECTION}`;
};

export interface ProjectTeamMemberPositionRole {
	positionId: string;
	roles: ProjectRole[];
}
export interface ProjectTeamMember {
	/**
	 * Id of the user.
	 */
	id: string;
	/**
	 * Roles the user is assigned
	 */
	effectiveRoles: ProjectRole[];
	/**
	 * All the positions on the project and associated roles.
	 */
	positionRoles: ProjectTeamMemberPositionRole[];
}

export enum ProjectLayoutType {
	DETAIL = 'DETAIL',
	WEATHER = 'WEATHER',
	FIELD_MANAGEMENT = 'FIELD_MANAGEMENT',
	RFI = 'RFI',
	DRAWINGS_SPECS = 'DRAWINGS_SPECS',
	CHECKLIST_COMPLETED = 'CHECKLIST_COMPLETED',
	MILESTONES = 'MILESTONES',
}

export interface ProjectLayout {
	position: number;
	type: ProjectLayoutType;
	enabled: boolean;
}

export interface ProjectBookmark {
	id: string;
	userId: string;
	name: string;
	path: string;
}

export interface Project {
	id: string;
	name: string;
	description?: string;
	portfolioId?: string;
	portfolioName?: string;
	organizationId: string;
	status: ProjectStatus;
	details: ProjectDetails;
	dates: ProjectDates;
	created: Date;
	updated: Date;
	address: string;
	city: string;
	state: string;
	zipCode: string;
	hasImage: boolean;
	latitude: string;
	longitude: string;
}

export interface ProjectDates {
	startDate?: Date;
	endDate?: Date;
	forecastStartDate?: Date;
	forecastFinishDate?: Date;
}

export interface ProjectDetails {
	contractValue?: number;
	contractType?: ContractType;
	constructionType: ConstructionType[];
	type: ProjectType;
}

export enum ProjectContactType {
	PROJECT_EXECUTIVE = 'PROJECT_EXECUTIVE',
	PROJECT_MANAGER = 'PROJECT_MANAGER',
	HEAD_ELECTRICIAN = 'HEAD_ELECTRICIAN',
	CONSTRUCTION_MANAGER = 'CONSTRUCTION_MANAGER',
	ROOF_LEAD = 'ROOF_LEAD',
	FIELD_ENGINEER = 'FIELD_ENGINEER',
}

export type ProjectContact = {
	type: ProjectContactType;
	contact: Contact;
};

export enum ContractType {
	LUMP_SUM = 'LUMP_SUM',
	COST_PLUS = 'COST_PLUS',
	GNP = 'GNP',
	CM_AT_RISK = 'CM_AT_RISK',
}

export enum ConstructionType {
	SOLAR = 'SOLAR',
	ROOFING = 'ROOFING',
	ELECTRICAL = 'ELECTRICAL',
	WOOD_FRAME = 'WOOD_FRAME',
	STEEL_FRAME = 'STEEL_FRAME',
	CONCRETE_FRAME = 'CONCRETE_FRAME',
	CONCRETE_STEEL_FRAME = 'CONCRETE_STEEL_FRAME',
}

export enum ProjectType {
	ENTERTAINMENT = 'ENTERTAINMENT',
	HEALTHCARE = 'HEALTHCARE',
	MEDICAL = 'MEDICAL',
	MULTI_FAMILY = 'MULTI_FAMILY',
	OFFICE = 'OFFICE',
	RETAIL = 'RETAIL',
	CHURCH = 'CHURCH',
	SCHOOL = 'SCHOOL',
}

export enum ProjectStatus {
	PRECONSTRUCTION = 'PRECONSTRUCTION',
	CONSTRUCTION = 'CONSTRUCTION',
	CLOSE_OUT = 'CLOSE_OUT',
	FINISHED = 'FINISHED',
	CANCELLED = 'CANCELLED',
}

export enum ProjectDrawingPriority {
	LOW = 'LOW',
	NORMAL = 'NORMAL',
	HIGH = 'HIGH',
	URGENT = 'URGENT',
}

export enum ProjectDrawingStatus {
	DRAFT = 'DRAFT',
	OPEN = 'OPEN',
	CLOSED = 'CLOSED',
}

export interface ProjectDrawing {
	id: string;
	name: string;
	status: ProjectDrawingStatus;
	issueDate: Date;
	dueDate?: Date;
	created: Date;
	updated: Date;
	priority: ProjectDrawingPriority;
	files: FirebaseFile[];
}
