import {
	IonButton,
	IonButtons,
	IonContent,
	IonFooter,
	IonHeader,
	IonModal,
	IonPage,
	IonSpinner,
	IonToolbar,
} from '@ionic/react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { useTranslation } from 'react-i18next';
import { firebaseAuth } from '../..';
import { useOmegaUser, useOmegaUserOrganizations } from '../../database/user';
import AcceptInvites from './AcceptInvites';
import CreateOrganization from './CreateOrganization';
import UserCreation from './UserCreation';
import Welcome from './Welcome';
import './style.css';

const FirstTimeSetup: React.FC = () => {
	const [firstTime, setFirstTime] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [saving, setSaving] = useState(false);
	const [user] = useAuthState(firebaseAuth);
	const { t } = useTranslation();
	const [omegaUser, loading, error] = useOmegaUser();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [userOrgs, _userOrgsLoading, userOrgsError] =
		useOmegaUserOrganizations();

	const steps = [t('welcome'), t('account'), t('invites'), t('organization')];

	useEffect(() => {
		const omegaUserNotExists =
			!error && user?.uid && !loading && omegaUser?.exists() === false;
		const noOrgs = !userOrgsError && userOrgs !== undefined && userOrgs.empty;
		if (omegaUserNotExists || noOrgs) {
			setModalOpen(true);
			setFirstTime(true);
		} else if (!firstTime || !noOrgs) {
			setModalOpen(false);
		}
	}, [user, omegaUser, error, loading, userOrgsError, userOrgs, firstTime]);

	const [activeStep, setActiveStep] = useState(0);

	const handleNext = () => {
		if (activeStep === 1 || activeStep === 3) {
			setSaving(true);
			return;
		}
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	};
	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	const saveComplete = (success: boolean) => {
		setSaving(false);
		if (success) {
			if (activeStep === 3) {
				setFirstTime(false);
				setModalOpen(false);
				return;
			}
			setActiveStep(prevActiveStep => prevActiveStep + 1);
		}
	};

	const renderActiveStep = () => {
		switch (activeStep) {
			case 0:
				return <Welcome />;
			case 1:
				return <UserCreation saveComplete={saveComplete} shouldSave={saving} />;
			case 2:
				return <AcceptInvites />;
			case 3:
				return (
					<CreateOrganization saveComplete={saveComplete} shouldSave={saving} />
				);
		}
	};

	return (
		<IonModal backdropDismiss={false} isOpen={modalOpen}>
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<Stepper alternativeLabel activeStep={activeStep}>
							{steps.map((label, _index) => {
								const stepProps = {};
								const labelProps = {};
								return (
									<Step key={label} {...stepProps}>
										<StepLabel {...labelProps}>{label}</StepLabel>
									</Step>
								);
							})}
						</Stepper>
					</IonToolbar>
				</IonHeader>
				<IonContent className="step-content">{renderActiveStep()}</IonContent>
				<IonFooter>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton disabled={activeStep === 0} onClick={handleBack}>
								{t('back')}
							</IonButton>
						</IonButtons>
						<IonButtons slot="end">
							<IonButton disabled={saving} onClick={handleNext}>
								{saving ? (
									<IonSpinner name="crescent" />
								) : activeStep !== steps.length - 1 ? (
									t('next')
								) : (
									t('finish')
								)}
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonFooter>
			</IonPage>
		</IonModal>
	);
};

export default FirstTimeSetup;
