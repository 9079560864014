import {
	IonAlert,
	IonButton,
	IonButtons,
	IonHeader,
	IonIcon,
	IonMenuButton,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { t } from 'i18next';
import { menuOutline } from 'ionicons/icons';
import { memo, useEffect, useState } from 'react';
import { useOrganization } from '../../database/organization';
import { useOmegaUser, useOmegaUserOrganizations } from '../../database/user';
import ProfilePopover from '../../modals/profile-popover/ProfilePopover';
import { useOrganizationState } from '../../state';
import { usePreferenceState } from '../../state/preference-state';
import useWindowDimensions from '../../util/useWindowDimensions';
import UserAvatar from '../user-avatar/UserAvatar';
import { HeaderProps } from './types';

const Header: React.FC<HeaderProps> = () => {
	const [omegaUser] = useOmegaUser();
	const [popoverState, setShowPopover] = useState<{
		showPopover: boolean;
		event: undefined | React.MouseEvent<HTMLIonButtonElement, MouseEvent>;
	}>({
		showPopover: false,
		event: undefined,
	});
	const [showSelectOrg, setShowSelectOrg] = useState(false);
	const omegaUserData = omegaUser?.data();
	const { width } = useWindowDimensions();
	const pref = usePreferenceState();
	const orgState = useOrganizationState();
	const [orgs] = useOmegaUserOrganizations();
	const currentOrg = orgState?.get()?.id;
	const [dbOrg] = useOrganization(currentOrg ?? 'NO_ORG');

	useEffect(() => {
		if (orgs !== undefined && !orgs.empty && !currentOrg) {
			const org = orgs.docs[0].data();
			orgState.set(org);
		}
	}, [currentOrg, orgState, orgs, showSelectOrg]);

	useEffect(() => {
		const name = dbOrg?.data()?.name;
		if (orgState.value && name && currentOrg && orgState.value?.name !== name) {
			// update org name
			orgState.set({
				...orgState.value,
				name,
			});
		}
	}, [currentOrg, dbOrg, orgState]);

	const closePopover = () => {
		setShowPopover({ event: undefined, showPopover: false });
	};

	const switchOrg = () => {
		setShowSelectOrg(true);
		closePopover();
	};

	return (
		<>
			<IonAlert
				isOpen={showSelectOrg}
				buttons={[
					{
						text: t('cancel'),
					},
					{
						text: t('select'),
						handler: () => {},
					},
				]}
				onDidDismiss={async value => {
					const org = value.detail.data?.values['org'];
					if (value.detail.data?.values['org']) {
						orgState.set(org);
					}
					setShowSelectOrg(false);
				}}
				header={t('selectOrganization')}
				message={t('selectOrganizationMessage')}
				inputs={orgs?.docs.map(doc => {
					const org = doc.data();
					return {
						name: org.id,
						type: 'radio',
						label: org.name,
						value: { org },
						checked: currentOrg === org.id,
					};
				})}
			/>
			<ProfilePopover
				switchOrg={switchOrg}
				event={popoverState.event}
				open={popoverState.showPopover}
				onClosed={closePopover}
			/>
			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						{width < 1200 && <IonMenuButton />}
						{width >= 1200 && (
							<IonButton
								onClick={() => {
									pref.merge({
										sideClosed: !pref.sideClosed.value,
									});
								}}
							>
								<b>
									<IonIcon slot="icon-only" icon={menuOutline} />
								</b>
							</IonButton>
						)}
					</IonButtons>
					<IonTitle>Convert Solar Construction</IonTitle>
					{omegaUserData ? (
						<IonButtons slot="end">
							<IonButton
								onClick={e => {
									e.persist();
									setShowPopover({ showPopover: true, event: e });
								}}
								fill="clear"
							>
								{width > 600
									? `${omegaUserData.firstName} ${omegaUserData.lastName}`
									: null}
								<UserAvatar />
							</IonButton>
						</IonButtons>
					) : null}
				</IonToolbar>
			</IonHeader>
		</>
	);
};

export default memo(Header);
