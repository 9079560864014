import { ProjectRole } from './OrganizationTeam';
import { PROJECTS_COLLECTION } from './Project';

export const PROJECTS_TEAM_POSITIONS_COLLECTION = 'positions';

export const getProjectPositionsCollectionPath = (id: string) => {
	return `${PROJECTS_COLLECTION}/${id}/${PROJECTS_TEAM_POSITIONS_COLLECTION}`;
};
export interface ProjectTeamPosition {
	id: string;
	name: string;
	description?: string;
	roles: ProjectRole[];
	users: string[];
}

export interface ProjectUserRole {
	id: string;
	roles: ProjectRole;
}
