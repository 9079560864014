import { OrganizationRole } from './Organization';

export const ORGANIZATION_INVITE_COLLECTION = 'organization-invites';

export interface OrganizationInvite {
	id: string;
	organizationId: string;
	organizationName: string;
	inviterId: string;
	inviterName: string;
	email: string;
	roles: OrganizationRole[];
	status: OrganizationInviteStatus;
	expire?: Date;
	created: Date;
}

export enum OrganizationInviteStatus {
	PENDING = 'PENDING',
	EXPIRED = 'EXPIRED',
	ACCEPTED = 'ACCEPTED',
	CANCELLED = 'CANCELLED',
	DECLINED = 'DECLINED',
}
