import { useTranslation } from 'react-i18next';
import UserInvites from '../user-invites/UserInvites';

const AcceptInvites: React.FC = () => {
	const { t } = useTranslation();

	return <UserInvites subtext={t('noInvitesSub')} />;
};

export default AcceptInvites;
