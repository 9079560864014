import { PhoneNumber } from './Phone';

export interface Company {
  id: string;
  name: string;
  address: string;
  phoneNumbers: PhoneNumber[];
  type: CompanyType;
  division: CompanyDivision;
}

export enum CompanyType {
  CONTRACTOR = 'Contractor',
  GENERAL_CONTRACTOR = 'General Contractor',
  OWNER = 'Owner',
  GOVERNMENT_AGENCY = 'Government Agency',
  CONSULTANT = 'Consultant',
  VENDOR = 'Vendor',
}

export enum CompanyDivision {
  ARCHITECT = 'Architect',
  Concrete = 'Concrete',
  CONSTRUCTION_MANAGEMENT = 'Construction Management',
  Consultant = 'Consultant',
  CONVEYING_SYSTEMS = 'Conveying Systems',
  DESIGN = 'Design',
  DOORS_AND_WINDOWS = 'Doors and Windows',
  ELECTRICAL = 'Electrical',
  EQUIPMENT = 'Equipment',
}
