import {
	IonApp,
	IonPage,
	IonSplitPane, setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { logEvent, setCurrentScreen } from 'firebase/analytics';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { analytics } from '.';
import FirstTimeSetup from './components/first-time-setup/FirstTimeSetup';
import Header from './components/header/Header';
import Menu from './components/menu/Menu';
import Routes from './Routes';
import { usePreferenceState } from './state/preference-state';
/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const logCurrentPage = () => {
	setCurrentScreen(analytics, window.location.pathname);
	logEvent(analytics, 'screen_view');
};

const AnalyticsComponent = () => {
	const history = useHistory();
	useEffect(() => {
		logCurrentPage(); // log the first page visit
		history.listen(() => {
			logCurrentPage();
		});
	}, [history]);
	return <div></div>;
};

const App: React.FC = () => {
	return (
		<IonApp>
			<FirstTimeSetup />
			<IonReactRouter>
				<AnalyticsComponent />
				{RenderPane()}
			</IonReactRouter>
		</IonApp>
	);
};

const RenderPane = () => {
	const pref = usePreferenceState();
	return (
		<IonSplitPane disabled={pref.sideClosed.value} when="xl" contentId="main">
			<Menu />

			<IonPage id="main">
				<Header />
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<Routes />
				</LocalizationProvider>
			</IonPage>
		</IonSplitPane>
	);
};

export default App;
