import { useIonToast } from '@ionic/react';
import { OmegaUser } from '@omega/shared';
import { memo, useEffect, useMemo } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { firebaseAuth } from '../..';
import { saveOmegaUser, useOmegaUser } from '../../database/user';
import { RecursivePartial } from '../../util/recursivePartial';
import ProfileForm from '../profile-form/ProfileForm';

export interface UserCreationProps {
	saveComplete: (success: boolean) => void;
	shouldSave: boolean;
}

const UserCreation: React.FC<UserCreationProps> = ({
	saveComplete,
	shouldSave,
}) => {
	const [user] = useAuthState(firebaseAuth);
	const { t } = useTranslation();
	const [omegaUser, loading, error] = useOmegaUser();

	const [presentErrorToast] = useIonToast();

	const defaultValues = useMemo(() => {
		return omegaUser?.exists()
			? omegaUser.data()
			: {
					phoneNumbers: [
						{
							label: 'cell',
							number: undefined,
						},
					],
			  };
	}, [omegaUser]);

	const formMethods = useForm<RecursivePartial<OmegaUser>>({
		defaultValues,
	});

	useEffect(() => {
		formMethods.reset(defaultValues);
	}, [defaultValues, formMethods]);

	const { handleSubmit, setValue } = formMethods;
	useEffect(() => {
		if (shouldSave) {
			handleSubmit(
				async (data: OmegaUser) => {
					if (!user?.uid) {
						presentErrorToast(t('userCreationFailed'), 5000);
						return;
					}
					try {
						await saveOmegaUser({ ...data, id: user.uid });
					} catch (ex) {
						console.error('There was an issue saving the user profile', ex);
						presentErrorToast(t('userCreationFailed'), 5000);
					} finally {
						saveComplete(true);
					}
				},
				async (errors: Object) => {
					presentErrorToast(t('missingRequiredFields'), 5000);
					console.error('Missing required fields', errors);

					saveComplete(false);
				},
			)();
		}
	}, [handleSubmit, presentErrorToast, saveComplete, shouldSave, t, user?.uid]);

	useEffect(() => {
		if (!error && user?.email && !loading && !omegaUser?.exists()) {
			setValue('email', user.email);
		}
	}, [error, loading, omegaUser, setValue, user?.email]);

	return (
		<FormProvider {...formMethods}>
			<ProfileForm />
		</FormProvider>
	);
};

export default memo(UserCreation, (prev, next) => {
	return prev.shouldSave === next.shouldSave;
});
