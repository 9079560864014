import { createState, useState } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import { OmegaUserOrganization } from '@omega/shared';

const CURRENT_ORG_KEY = 'current-org';

const state = createState<OmegaUserOrganization | null>(null);
state.attach(Persistence(CURRENT_ORG_KEY));

export const useOrganizationState = () => {
	return useState<OmegaUserOrganization | null>(state);
};
