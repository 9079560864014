import { PROJECTS_COLLECTION } from './Project';

export interface PunchItem {
	id: string;
	name: string;
	description: string;
	location?: string;
	created: Date;
	updated: Date;
	status: PunchItemStatus;
	userId: string;
}

export enum PunchItemStatus {
	DRAFT = 'DRAFT',
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETED = 'COMPLETED',
	CANCELLED = 'CANCELLED',
}

export const PUNCH_ITEM_COLLECTION = 'punch-items';
export const PUNCH_ITEM_NOTES_COLLECTION = 'notes';

export const getProjectPunchItemsCollection = (projectId: string) =>
	`${PROJECTS_COLLECTION}/${projectId}/${PUNCH_ITEM_COLLECTION}`;

// projects/:projectId/punch-items/:punchItemId/notes
export const getProjectPunchItemNotesCollection = (
	projectId: string,
	punchItemId: string,
) =>
	`${PROJECTS_COLLECTION}/${projectId}/${PUNCH_ITEM_COLLECTION}/${punchItemId}/${PUNCH_ITEM_NOTES_COLLECTION}/`;
