export const USER_FILE_ROOT = 'user-files';
export const USER_ICON_FOLDER = 'icon';
export const PORTFOLIO_FILE_ROOT = 'portfolio-files';
export const PROJECT_FILE_ROOT = 'project-files';
export const PROJECT_IMAGE_PATH = 'image';

export const PROJECT_IMAGE_NAME = 'cover-image';

export const getProjectFileImagePath = (id: string) => {
	return `${PROJECT_FILE_ROOT}/${id}/${PROJECT_IMAGE_PATH}/${PROJECT_IMAGE_NAME}`;
};
