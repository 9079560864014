import { PhoneNumber } from './Phone';

export const ORGANIZATIONS_COLLECTION = 'organizations';

export const ORGANIZATION_OMEGA_USER_COLLECTION = 'users';

export const getOrganizationOmegaUserCollectionPath = (id: string) => {
	return `${ORGANIZATIONS_COLLECTION}/${id}/${ORGANIZATION_OMEGA_USER_COLLECTION}`;
};

export interface Organization {
	id: string;
	name: string;
	email: string;
	address: string;
	city: string;
	state: string;
	zipCode: string;
	created: Date;
	update: Date;
	phoneNumbers: PhoneNumber[];
}

export interface OrganizationMember {
	id: string;
	firstName: string;
	lastName: string;
	roles: OrganizationRole[];
	fullName: string;
}

export interface OrganizationMemberToRemove {
	userId: string;
	organizationId: string;
}

export enum OrganizationRole {
	OWNER = 'OWNER',
	ADMIN = 'ADMIN',
	CONTRIBUTOR = 'CONTRIBUTOR',
	VIEWER = 'VIEWER',
}

export enum OrganizationPlan {
	TRIAL = 'TRIAL',
	STARTER = 'STARTER',
	BUSINESS = 'BUSINESS',
	ENTERPRISE = 'ENTERPRISE',
}
export interface OrganizationSubscription {
	organizationId: string;
	plan: OrganizationPlan;
	trialDetails: OrganizationTrialDetails;
}

export interface OrganizationTrialDetails {
	expirationDate: Date;
	startDate: Date;
	status: OrganizationTrialStatus;
}

export enum OrganizationTrialStatus {
	IN_PROCESS = 'IN_PROCESS',
	FINISHED = 'FINISHED',
}
