import { ORGANIZATIONS_COLLECTION } from './Organization';
import { PROJECTS_COLLECTION } from './Project';

export const CHECKLIST_COLLECTION = 'checklists';

export const CHECKLIST_ITEMS_COLLECTION = 'items';
export const CHECKLIST_NOTES_COLLECTION = 'notes';
export const CHECKLIST_ACTIVITY_LOG_COLLECTION = 'logs';

export const CHECKLIST_COVER_IMAGE = 'cover-image';

export const getChecklistImageLocation = (
	projectId: string,
	checklistId: string,
) =>
	`${PROJECTS_COLLECTION}/${projectId}/${CHECKLIST_COLLECTION}/${checklistId}/${CHECKLIST_COVER_IMAGE}`;

export const getProjectChecklistCollection = (projectId: string) =>
	`${PROJECTS_COLLECTION}/${projectId}/${CHECKLIST_COLLECTION}`;

export const getProjectChecklistActivityLogCollection = (
	projectId: string,
	checklistId: string,
) =>
	`${PROJECTS_COLLECTION}/${projectId}/${CHECKLIST_COLLECTION}/${checklistId}/${CHECKLIST_ACTIVITY_LOG_COLLECTION}`;

// projects/:projectId/checklists/:checklistId/:checklistItemId
export const getProjectChecklistNotesCollection = (
	projectId: string,
	checklistId: string,
	itemId: string,
) =>
	`${PROJECTS_COLLECTION}/${projectId}/${CHECKLIST_COLLECTION}/${checklistId}/${CHECKLIST_ITEMS_COLLECTION}/${itemId}/${CHECKLIST_NOTES_COLLECTION}`;

export const getProjectChecklistMainNotesCollection = (
	projectId: string,
	checklistId: string,
) =>
	`${PROJECTS_COLLECTION}/${projectId}/${CHECKLIST_COLLECTION}/${checklistId}/${CHECKLIST_NOTES_COLLECTION}`;

/**
 * Returns the collection reference for an organizations project checklist templates.
 * @param organizationId to look up the templates
 * @returns the string of the org's projects checklist templates.
 */
export const getChecklistTemplateCollection = (organizationId: string) =>
	`${ORGANIZATIONS_COLLECTION}/${organizationId}/${CHECKLIST_COLLECTION}`;

// projects/:projectId/checklists/:checklistId
export interface Checklist {
	id: string;
	name: string;
	description?: string;
	created: Date;
	updated: Date;
	status: ChecklistStatus;
	locked: boolean;
	type?: string;
	sections: ChecklistSection[];
	lastUserId: string;
}

export enum ChecklistStatus {
	DRAFT = 'DRAFT',
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETE = 'COMPLETE',
}

export interface ChecklistSection {
	name: string;
	items: ChecklistItem[];
}

export interface ChecklistItem {
	id?: string;
	description: string;
	status: ChecklistItemStatus;
}

export enum ChecklistItemStatus {
	NOT_APPLICABLE = 'NOT_APPLICABLE',
	INCOMPLETE = 'INCOMPLETE',
	COMPLETE = 'COMPLETE',
}

// organizations/:orgId/checklists/:checkListId
export type ChecklistTemplate = Omit<
	Checklist,
	'status' | 'locked' | 'created' | 'updated'
>;

export function getChecklistCompleted(checklists: Checklist) {
	let totalAmount = 0;
	let completedAmount = 0;

	checklists.sections.forEach(section =>
		section.items.forEach(item => {
			totalAmount++;
			if (
				item.status === ChecklistItemStatus.COMPLETE ||
				item.status === ChecklistItemStatus.NOT_APPLICABLE
			) {
				completedAmount++;
			}
		}),
	);
	return {
		completedAmount,
		totalAmount,
		isCompleted: completedAmount === totalAmount,
	};
}
