import { FirebaseOptions } from 'firebase/app';

export const firebaseConfig: FirebaseOptions = {
	apiKey: 'AIzaSyDxAK5dYGopC8j7LVKs2ML6ER4OLY9uXeo',
	authDomain: 'omega-project-management.firebaseapp.com',
	projectId: 'omega-project-management',
	storageBucket: 'omega-project-management.appspot.com',
	messagingSenderId: '917143158491',
	appId: '1:917143158491:web:ddd409e59fab6465e216fe',
	measurementId: 'G-XDNG09BRTM',
};

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type Prev = [never, 0, 1, 2, 3, 4, ...0[]];
export type Join<K, P> = K extends string | number
	? P extends string | number
		? `${K}${'' extends P ? '' : '.'}${P}`
		: never
	: never;

export type Paths<T, D extends number = 10> = [D] extends [never]
	? never
	: T extends object
	? {
			[K in keyof T]-?: K extends string | number
				? `${K}` | Join<K, Paths<T[K], Prev[D]>>
				: never;
	  }[keyof T]
	: '';

export type Leaves<T, D extends number = 10> = [D] extends [never]
	? never
	: T extends object
	? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
	: '';
