import {
	IonItem,
	IonLabel,
	IonList,
	IonPopover,
	IonToggle,
	useIonAlert,
} from '@ionic/react';
import Divider from '@mui/material/Divider';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { firebaseAuth } from '../..';
import UserAvatar from '../../components/user-avatar/UserAvatar';
import { updateOmegaUser, useOmegaUser } from '../../database/user';
import { LOGIN_ROUTE, PROFILE_ROUTE } from '../../Routes';
import { useOrganizationState } from '../../state';
import { usePreferenceState } from '../../state/preference-state';
import './style.css';
import { ProfilePopoverProps } from './types';

const ProfilePopover: React.FC<ProfilePopoverProps> = ({
	open,
	onClosed,
	event,
	switchOrg,
}) => {
	const [omegaUser] = useOmegaUser();

	const omegaUserData = omegaUser?.data();
	const currentLanguage = omegaUserData?.language;
	const name = omegaUserData
		? `${omegaUserData.firstName} ${omegaUserData.lastName}`
		: null;

	const history = useHistory();
	const { t, i18n } = useTranslation();

	useEffect(() => {
		const lang = omegaUserData?.language;
		console.log(lang);
		if (lang) {
			i18n.changeLanguage(lang, (err, t) => {
				if (err) {
					return console.log('something went wrong loading', err);
				}
			});
		}
	}, [i18n, omegaUserData?.language]);

	const [present] = useIonAlert();
	const currentOrgState = useOrganizationState();
	const preference = usePreferenceState();

	const currentOrgName = useMemo(
		() => currentOrgState.get()?.name ?? '',
		[currentOrgState],
	);

	useEffect(() => {
		document.body.classList.toggle('dark', preference.get().darkMode);
	}, [preference]);

	const logout = async () => {
		present({
			message: t('confirmLogout'),
			buttons: [
				t('cancel'),
				{
					text: 'Ok',
					handler: async () => {
						localStorage.clear();
						await firebaseAuth.signOut();
						history.push(LOGIN_ROUTE);
						window.location.reload();
					},
				},
			],
		});
	};

	const changeLanguage = () => {
		present({
			message: t('changeLanguage'),
			inputs: [
				{
					type: 'radio',
					name: 'english',
					label: t('english'),
					value: 'en',
					checked: currentLanguage === undefined || currentLanguage === 'en',
				},
				{
					type: 'radio',
					name: 'spanish',
					label: t('spanish'),
					value: 'es',
					checked: currentLanguage === 'es',
				},
			],
			onDidDismiss: e => {
				const newLang = e.detail.data?.values;
				if (newLang) {
					updateOmegaUser({ language: newLang });
				}
			},
			buttons: [
				t('cancel'),
				{
					text: 'Ok',
					handler: async () => {},
				},
			],
		});
	};

	const viewProfile = () => {
		onClosed();
		history.push(PROFILE_ROUTE);
	};

	const toggleDarkMode = () => {
		const darkMode = !preference.value.darkMode;
		preference.set({
			darkMode,
		});
	};

	return (
		<>
			<IonPopover event={event} onIonPopoverDidDismiss={onClosed} isOpen={open}>
				<div className="profile-popover-root">
					<div className="user-container">
						<UserAvatar />
						<div className="user-name-org-container">
							<div className="user-name">{name}</div>
							<div className="user-org">{currentOrgName}</div>
						</div>
					</div>
					<Divider />
					<IonList lines="none">
						<IonItem>
							<IonLabel>{t('toggleDarkMode')}</IonLabel>
							<IonToggle
								onIonChange={toggleDarkMode}
								checked={preference.value.darkMode}
							/>
						</IonItem>
						<IonItem button onClick={switchOrg}>
							<IonLabel>{t('switchOrganization')}</IonLabel>
						</IonItem>

						<IonItem button onClick={viewProfile}>
							<IonLabel>{t('viewProfile')}</IonLabel>
						</IonItem>

						<IonItem button onClick={changeLanguage}>
							<IonLabel>{t('language')}</IonLabel>
						</IonItem>

						<IonItem button onClick={logout}>
							<IonLabel color="danger">{t('logout')}</IonLabel>
						</IonItem>
					</IonList>
				</div>
			</IonPopover>
		</>
	);
};

export default ProfilePopover;
