import { PROJECTS_COLLECTION } from './Project';

export const DAILY_REPORTS_COLLECTION = 'daily-reports';
export const DAILY_REPORT_NOTES_COLLECTION = 'notes';
export const DAILY_REPORT_TIME_COLLECTION = 'time';

export const getProjectDailyReportCollection = (projectId: string) => {
	return `${PROJECTS_COLLECTION}/${projectId}/${DAILY_REPORTS_COLLECTION}`;
};

export const getProjectDailyReport = (projectId: string, reportId: string) => {
	return `${PROJECTS_COLLECTION}/${projectId}/${DAILY_REPORTS_COLLECTION}/${reportId}`;
};

// projects/:projectId/drawing/:drawingId/:checklistItemId
export const getProjectDailyReportNotesCollection = (
	projectId: string,
	dailyReportId: string,
) =>
	`${PROJECTS_COLLECTION}/${projectId}/${DAILY_REPORTS_COLLECTION}/${dailyReportId}/${DAILY_REPORT_NOTES_COLLECTION}`;

export const getProjectDailyReportTimeLogCollection = (
	projectId: string,
	dailyReportId: string,
) =>
	`${PROJECTS_COLLECTION}/${projectId}/${DAILY_REPORTS_COLLECTION}/${dailyReportId}/${DAILY_REPORT_TIME_COLLECTION}`;

export enum DailyReportStatus {
	OPEN = 'OPEN',
	CLOSED = 'CLOSED',
	APPROVED = 'APPROVED',
}

export interface DailyReport {
	id: string;
	date: Date;
	status: DailyReportStatus;
	locked: boolean;
	weatherReports: DailyWeatherReport[];
	totalTime: number;
	totalTimeEntries: number;
}

export interface DailyWeatherReport {
	date: Date;
	weather: Forecast;
	userId: string;
}

export interface TimeLogEntry {
	id: string;
	userId: string;
	hours: number;
	description?: string;
}

export interface Forecast {
	/**
	 * Geographical coordinates of the location (latitude).
	 */
	lat: number;

	/**
	 * Geographical coordinates of the location (longitude)
	 */
	lon: number;

	/**
	 * Timezone name for the requested location.
	 */
	timezone: string;

	/**
	 * Shift in seconds from UTC.
	 */
	timezone_offset: number;

	/**
	 * A data block containing the current weather conditions at the requested location.
	 */
	current?: CurrentDataBlock;
}

export interface CurrentDataBlock extends DataBlock {
	/**
	 * Sunrise time as a UTC Unix timestamp.
	 */
	sunrise: number;

	/**
	 * Sunsise time as a UTC Unix timestamp.
	 */
	sunset: number;

	/**
	 * Forecasted temperature.
	 *
	 * Depending on the [[Units]] selected this value will change.
	 *
	 * Units – default: kelvin, metric: Celsius, imperial: Fahrenheit.
	 */
	temp: number;

	/**
	 * Temperature that accounts for the human perception of weather.
	 *
	 * Depending on the [[Units]] selected this value will change.
	 *
	 * [[Units]] – default: kelvin, metric: Celsius, imperial: Fahrenheit.
	 */
	feels_like: number;

	/**
	 * Average visibility measured in metres (m).
	 */
	visibility: number;

	/**
	 * Midday UV index.
	 */
	uvi: number;

	/**
	 * Volume of rain for the last hour, measured in mm.
	 */
	rain?: PrecipitationDataPoint;

	/**
	 * Volume of snow for the last hour, measured in mm.
	 */
	snow?: PrecipitationDataPoint;
}

export interface PrecipitationDataPoint {
	/**
	 * Precipitation volume for the last hour, measured in millimeters (mm).
	 */
	'1h': number;
}

/**
 * @internal
 *
 * Base datablock interface
 *
 */
export interface DataBlock {
	/**
	 * Time of the forecasted data, unix, UTC.
	 */
	dt: number;

	/**
	 * Forecasted temperature.
	 *
	 * Depending on the [[Units]] selected this value will change.
	 *
	 * Units – default: kelvin, metric: Celsius, imperial: Fahrenheit.
	 */
	temp: number | TemperatureBlock;

	/**
	 * Temperature that accounts for the human perception of weather.
	 *
	 * Depending on the [[Units]] selected this value will change.
	 *
	 * [[Units]] – default: kelvin, metric: Celsius, imperial: Fahrenheit.
	 */
	feels_like: number | TemperatureDayBlock;

	/**
	 * Atmospheric temperature on the sea level, measured in hectopascal (hPa).
	 */
	pressure: number;

	/**
	 * Humidity percentage.
	 */
	humidity: number;

	/**
	 *  Atmospheric temperature (varying according to pressure and humidity) below which water droplets begin to condense and dew can form.
	 *
	 * [[Units]] – default: kelvin, metric: Celsius, imperial: Fahrenheit.
	 */
	dew_point: number;

	/**
	 * Cloudiness percent.
	 */
	clouds: number;

	/**
	 * Average visibility measured in metres (m).
	 */
	visibility?: number;

	/**
	 * Wind speed.
	 *
	 * Units – default: metre/sec, metric: metre/sec, imperial: miles/hour
	 */
	wind_speed: number;

	/**
	 * Wind gust.
	 *
	 * Units – default: metre/sec, metric: metre/sec, imperial: miles/hour.
	 */
	wind_gust?: number;

	/**
	 * Wind direction in degrees (meteorological)
	 */
	wind_deg: number;

	/**
	 * Probability of precipitation.
	 */
	pop?: number;

	/**
	 * Volume of rain for the last hour, measured in mm.
	 */
	rain?: PrecipitationDataPoint | number;

	/**
	 * Volume of snow for the last hour, measured in mm.
	 */
	snow?: PrecipitationDataPoint | number;

	/**
	 * A block of user-facing weather information.
	 */
	weather: WeatherBlock[];

	/**
	 * Midday UV index.
	 */
	uvi?: number;
}

export interface TemperatureBlock extends TemperatureDayBlock {
	/**
	 * Minimum daily temperature.
	 */
	min: number;

	/**
	 * Maximum daily temperature.
	 */
	max: number;
}

export interface TemperatureDayBlock {
	/**
	 * Morning temperature.
	 */
	morn: number;

	/**
	 * Day temperature.
	 */
	day: number;

	/**
	 * Evening temperature.
	 */
	eve: number;

	/**
	 * Night temperature.
	 */
	night: number;
}

/**
 * User-facing information about the weather conditions.
 */
export interface WeatherBlock {
	/**
	 * Weather condition id.
	 *
	 * See {@link https://openweathermap.org/weather-conditions#Weather-Condition-Codes-2 Weather Condition Codes} for more info.
	 */
	id: number;

	/**
	 * Group of weather parameters (Rain, Snow, Extreme etc.)
	 */
	main: string;

	/**
	 * Weather condition within the group.
	 *
	 * The description can be translated using the `lang=` parameter in the request.
	 */
	description: string;

	/**
	 * Weather condition icon id.
	 */
	icon: WeatherIcon;
}

export enum WeatherIcon {
	ClearSky = '01d',
	ClearSkyNight = '01n',

	FewClouds = '02d',
	FewCloudsNight = '0n',

	ScatteredClouds = '03d',
	ScatteredCloudsNight = '03n',

	BrokenClouds = '04d',
	BrokenCloudsNight = '04n',

	ShowerRain = '09d',
	ShowerRainNight = '09n',

	Rain = '10d',
	RainNight = '10n',

	Thunderstorm = '11d',
	ThunderstormNight = '11n',

	Snow = '13d',
	SnowNight = '13n',

	Mist = '50d',
	MistNight = '50n',
}
