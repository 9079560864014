import { ORGANIZATIONS_COLLECTION } from './Organization';

export const ORGANIZATION_TEAMS_COLLECTION = 'teams';

export const getOrganizationTeamsCollectionPath = (id: string) => {
	return `${ORGANIZATIONS_COLLECTION}/${id}/${ORGANIZATION_TEAMS_COLLECTION}`;
};
export interface OrganizationTeam {
	id: string;
	name: string;
	description?: string;
	positions: TeamPosition[];
}

export enum ProjectRole {
	MANAGEMENT = 'MANAGEMENT',
	CONTRIBUTOR = 'CONTRIBUTOR',
	FIELD_SUPERVISOR = 'FIELD_SUPERVISOR',
	FIELD_PERSONNEL = 'FIELD_PERSONNEL',
}

export interface TeamPosition {
	id: string;
	name: string;
	description?: string;
	roles: ProjectRole[];
	defaultUsers: string[];
}
