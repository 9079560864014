import { IonIcon } from '@ionic/react';
import Avatar from 'avataaars';
import { personSharp } from 'ionicons/icons';
import { useCachedOmegaUser } from '../../database/user';
import './style.css';
import { UserAvatarProps } from './types';

const UserAvatar: React.FC<UserAvatarProps> = ({
	className,
	onClick,
	userId,
}) => {
	const { user } = useCachedOmegaUser(userId);

	const customAvatar = user?.customAvatar;

	const onAvatarClick = () => {
		if (onClick) {
			onClick();
		}
	};

	return (
		<>
			{!customAvatar ? (
				<div className={className}>
					<IonIcon className='m-auto' onClick={onAvatarClick} slot="end" icon={personSharp} />
				</div>
			) : (
				<span onClick={onAvatarClick}>
					<Avatar
						className={`avatar-img ${className ?? ''}`}
						{...customAvatar}
					/>
				</span>
			)}
		</>
	);
};

export default UserAvatar;
