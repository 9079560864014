export const PORTFOLIOS_COLLECTION = 'portfolios';

export const PORTFOLIOS_PROJECTS_COLLECTION = 'projects';

export const getPortfolioProjectCollectionPath = (portfolioId: string) =>
	`${PORTFOLIOS_COLLECTION}/${portfolioId}/${PORTFOLIOS_PROJECTS_COLLECTION}`;

export interface Portfolio {
	id: string;
	name: string;
	description?: string;
	organizationId: string;
}

export interface PortfolioProject {
	id: string;
	name: string;
}
