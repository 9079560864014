import {
	getOrganizationOmegaUserCollectionPath,
	Organization,
	OrganizationMember,
	OrganizationMemberToRemove,
	ORGANIZATIONS_COLLECTION,
} from '@omega/shared';
import {
	collection,
	CollectionReference,
	doc,
	getDocs,
	limit,
	orderBy,
	query,
	QueryConstraint,
	setDoc,
	updateDoc,
	where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useCollection, useDocument } from 'react-firehooks/firestore';
import { getGenericConverter } from '.';
import { firebaseFunctions, firestoreApp } from '..';

export const getCreateOrganization = () =>
	httpsCallable<Organization>(
		firebaseFunctions,
		'apiCreateOrg-default-createOrganization',
	);

const orgConverter = getGenericConverter<Organization>();

const orgMemberConverter = getGenericConverter<OrganizationMember>();

export function getOrganizationCollection(): CollectionReference<Organization> {
	return collection(firestoreApp, ORGANIZATIONS_COLLECTION).withConverter(
		orgConverter,
	);
}

export const getRemoveUserFromOrg = () =>
	httpsCallable<OrganizationMemberToRemove>(
		firebaseFunctions,
		'apiCreateOrg-default-removeUserFromOrg',
	);

export function useOrganization(id: string) {
	return useDocument(doc(getOrganizationCollection(), id));
}

export function getOrganizationMemberCollection(
	id: string,
): CollectionReference<OrganizationMember> {
	return collection(
		firestoreApp,
		getOrganizationOmegaUserCollectionPath(id),
	).withConverter(orgMemberConverter);
}

export async function saveMemberOrganization(
	orgId: string,
	orgMember: OrganizationMember,
) {
	return updateDoc<OrganizationMember>(
		doc(getOrganizationMemberCollection(orgId), orgMember.id),
		orgMember,
	);
}

export function useOrganizationMembers(id: string) {
	return useCollection(getOrganizationMemberCollection(id));
}

export function searchOrgMembers(
	orgId: string,
	searchText: string,
	limitResults: number = 20,
) {
	const queryConstraints: QueryConstraint[] = [limit(limitResults)];

	if (searchText) {
		queryConstraints.push(
			orderBy('fullName'),
			where('fullName', '>=', searchText.toUpperCase()),
			where('fullName', '<=', searchText.toLowerCase() + '\uf8ff'),
		);
	}

	return getDocs(
		query(getOrganizationMemberCollection(orgId), ...queryConstraints),
	);
}

export function saveOrganization(organization: Organization) {
	return setDoc<Organization>(
		doc(getOrganizationCollection(), organization.id),
		organization,
	);
}
