import { createState, useState } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';

const PREFERENCE_KEY = 'preference-state';

export interface UserPreference {
	darkMode: boolean;
	sideClosed?: boolean;
}

const state = createState<UserPreference>({
	darkMode: false,
	sideClosed: false,
});
state.attach(Persistence(PREFERENCE_KEY));

export const usePreferenceState = () => {
	return useState<UserPreference>(state);
};
