import { useTranslation } from 'react-i18next';

const Welcome: React.FC = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col justify-center items-center">
			<img className="welcome-logo" src="/assets/logo.png" alt="logo" />
			<h1 className="text-xl my-3">{t('welcome')}</h1>
			<p className="m-3">{t('welcomeMessage')}</p>
		</div>
	);
};

export default Welcome;
