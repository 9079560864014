import { IonRouterOutlet, isPlatform } from '@ionic/react';
import Skeleton from '@mui/material/Skeleton';
import { getAuth } from 'firebase/auth';
import { AnimatePresence } from 'framer-motion';
import { lazy, Suspense, useEffect, useRef } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { Redirect } from 'react-router-dom';
import { GuardedRoute, GuardProvider } from 'react-router-guards';
import {
	GuardFunctionRouteProps,
	GuardToRoute,
	Next,
} from 'react-router-guards/dist/types';
import { firebaseAuth } from '.';

const ChecklistTemplates = lazy(
	() => import('./pages/checklist-templates/ChecklistTemplates'),
);

const Budget = lazy(() => import('./pages/budget/Budget'));
const Checklists = lazy(() => import('./pages/checklists/Checklists'));
const DailyReports = lazy(() => import('./pages/daily-reports/DailyReports'));
const DailyReport = lazy(() => import('./pages/daily-report/DailyReport'));
const DrawingsSpecs = lazy(
	() => import('./pages/drawings-specs/DrawingsSpecs'),
);
const FieldManagement = lazy(
	() => import('./pages/field-management/FieldManagement'),
);
const FieldWorkDirectives = lazy(
	() => import('./pages/field-work-directives/FieldWorkDirectives'),
);

const Checklist = lazy(() => import('./pages/checklist/Checklist'));

const Login = lazy(() => import('./pages/login/Login'));
const NoticesToComply = lazy(
	() => import('./pages/notices-comply/NoticesToComply'),
);
const PortfolioSettings = lazy(
	() => import('./pages/portfolio-settings/PortfolioSettings'),
);
const Portfolio = lazy(() => import('./pages/portfolio/Portfolio'));
const Portfolios = lazy(() => import('./pages/portfolios/Portfolios'));
const Profile = lazy(() => import('./pages/profile/Profile'));
const ProjectSettings = lazy(
	() => import('./pages/project-settings/ProjectSettings'),
);
const Project = lazy(() => import('./pages/project/Project'));
const Projects = lazy(() => import('./pages/projects/Projects'));
const PunchItems = lazy(() => import('./pages/punch-items/PunchItems'));
const SafetyNotices = lazy(
	() => import('./pages/safety-notices/SafetyNotices'),
);
const Drawing = lazy(() => import('./pages/drawing/Drawing'));
const DrawingView = lazy(() => import('./pages/drawing-view/DrawingView'));
const RequestForInfos = lazy(
	() => import('./pages/request-for-infos/RequestForInfos'),
);
const RequestForInfo = lazy(
	() => import('./pages/request-for-info/RequestForInfo'),
);
const OrganizationSettings = lazy(
	() => import('./pages/organization-settings/OrganizationSettings'),
);
const MyAssignments = lazy(
	() => import('./pages/my-assignments/MyAssignments'),
);

const ManageOrganizationTeam = lazy(
	() => import('./pages/manage-organization-team/ManageOrganizationTeam'),
);

const ManageOrganizationSeats = lazy(
	() => import('./pages/manage-organization-seats/ManageOrganizationSeats'),
);

const ManageOrgTeamPositions = lazy(
	() => import('./pages/manage-org-team-positions/ManageOrgTeamPositions'),
);
const ManageProjectTeam = lazy(
	() => import('./pages/manage-project-team/ManageProjectTeam'),
);
const MyProjects = lazy(() => import('./pages/my-projects/MyProjects'));

const ProjectAssignments = lazy(
	() => import('./pages/project-assignments/ProjectAssignments'),
);

const ProjectAssignment = lazy(
	() => import('./pages/project-assignment/ProjectAssignment'),
);
const PunchItem = lazy(() => import('./pages/punch-item/PunchItem'));
const ProjectDocuments = lazy(
	() => import('./pages/project-documents/ProjectDocuments'),
);

const Milestones = lazy(() => import('./pages/milestones/Milestones'));

const Board = lazy(() => import('./pages/board/Board'));
const Boards = lazy(() => import('./pages/boards/Boards'));

export const ORGANIZATION_PREFIX = '/organization/';
export const PROJECT_PREFIX = '/project/';
export const PROFILE_ROUTE = '/profile';
export const ORGANIZATION_SETTINGS_ROUTE = `${ORGANIZATION_PREFIX}settings`;
export const ORGANIZATION_TEAM_ROUTE = `${ORGANIZATION_PREFIX}team`;
export const ORGANIZATION_TEAM_POS_ROUTE = `${ORGANIZATION_PREFIX}team/:id`;
export const ORGANIZATION_SEATS_ROUTE = `${ORGANIZATION_PREFIX}seats`;
export const PORTFOLIOS_ROUTE = '/portfolios';
export const PORTFOLIO_ROUTE = '/portfolio/:id';
export const LOGIN_ROUTE = '/login';
export const PROJECTS_ROUTE = '/projects';
export const BOARDS_ROUTE = '/boards';
export const BOARD_ROUTE = `/board/:id`;
export const PROJECT_ROUTE = `${PROJECT_PREFIX}:id`;
export const CHECKLIST_TEMPLATES_ROUTE = '/checklists-templates';
export const PROJECT_TEAM_ROUTE = `${PROJECT_ROUTE}/team`;
export const PROJECT_DRAWINGS_SPEC_ROUTE = `${PROJECT_ROUTE}/drawings`;
export const PROJECT_DRAWING_ROUTE = `${PROJECT_ROUTE}/drawings/:drawingId`;
export const PROJECT_DRAWING_REVISION_ROUTE = `${PROJECT_ROUTE}/drawings/:drawingId/:revisionId`;
export const FIELD_MANAGEMENT_ROUTE = `${PROJECT_ROUTE}/field-management`;
export const CHECKLISTS_ROUTE = `${PROJECT_ROUTE}/checklists/:action`;
export const CHECKLIST_ROUTE = `${PROJECT_ROUTE}/checklist/:checklistId`;
export const DAILY_REPORTS_ROUTE = `${PROJECT_ROUTE}/daily-reports/:action`;
export const DAILY_REPORT_ROUTE = `${PROJECT_ROUTE}/daily-report/:dailyReportId`;
export const FIELD_WORK_DIRECTIVES_ROUTE = `${PROJECT_ROUTE}/field-work-directives`;
export const NOTICES_ROUTE = `${PROJECT_ROUTE}/notices`;
export const PUNCH_ITEMS_ROUTE = `${PROJECT_ROUTE}/punch-items`;
export const PUNCH_ITEM_ROUTE = `${PROJECT_ROUTE}/punch-item/:punchItemId`;
export const RFIS_ROUTE = `${PROJECT_ROUTE}/rfis`;
export const RFI_ROUTE = `${PROJECT_ROUTE}/rfis/:rfiId`;
export const SAFETY_NOTICES_ROUTE = `${PROJECT_ROUTE}/safety-notices`;
export const PROJECT_BUDGET_SPEC_ROUTE = `${PROJECT_ROUTE}/budget`;
export const PROJECT_SETTINGS_ROUTE = '/project-settings/:id';
export const PROJECT_DOCUMENTS_ROUTE = '/project-documents/:id';
export const PROJECT_ASSIGNMENTS_ROUTE = `${PROJECT_ROUTE}/assignments`;
export const PROJECT_ASSIGNMENT_ROUTE = `${PROJECT_ROUTE}/assignment/:assignmentId`;
export const PORTFOLIO_SETTINGS_ROUTE = '/portfolio-settings/:id';
export const MY_ASSIGNMENTS_ROUTE = '/my-assignments';
export const MY_PROJECTS_ROUTE = '/my-projects';
export const MILESTONES_ROUTE = `${PROJECT_ROUTE}/milestones`;

const Routes: React.FC = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_user, loading] = useAuthState(getAuth());
	const loadingRef = useRef(true);

	useEffect(() => {
		loadingRef.current = loading;
	}, [loading]);

	const requireLogin = async (
		to: GuardToRoute,
		from: GuardFunctionRouteProps | null,
		next: Next,
	) => {
		await new Promise<void>(resolve => {
			const interval = setInterval(() => {
				if (!loadingRef.current) {
					clearInterval(interval);
					resolve();
				}
			});
		});

		if (!to.meta.insecure && !firebaseAuth.currentUser) {
			next.redirect(LOGIN_ROUTE);
		} else if (to.meta.skipLoggedIn && firebaseAuth.currentUser) {
			next.redirect(PROJECTS_ROUTE);
		} else {
			next();
		}
	};

	const marginTop = isPlatform('ios') ? 44 : 56;

	return (
		<IonRouterOutlet style={{ marginTop }}>
			<GuardProvider guards={[requireLogin]}>
				<AnimatePresence>
					<Suspense
						fallback={
							<Skeleton className="loading-route" variant="rectangular" />
						}
					>
						<GuardedRoute key="default" path="/" exact={true}>
							<Redirect to={PROJECTS_ROUTE} />
						</GuardedRoute>
						<GuardedRoute
							key="drawing-view"
							path={PROJECT_DRAWING_REVISION_ROUTE}
							component={DrawingView}
						/>
						<GuardedRoute
							key="project-drawing"
							path={PROJECT_DRAWING_ROUTE}
							component={Drawing}
						/>
						<GuardedRoute
							key="login"
							meta={{ insecure: true, skipLoggedIn: true }}
							path={LOGIN_ROUTE}
							exact={true}
							component={Login}
						/>
						<GuardedRoute
							key="portfolios"
							path={PORTFOLIOS_ROUTE}
							component={Portfolios}
						/>
						<GuardedRoute
							key="org-settings"
							path={ORGANIZATION_SETTINGS_ROUTE}
							component={OrganizationSettings}
						/>
						<GuardedRoute
							key="portfolio"
							path={PORTFOLIO_ROUTE}
							component={Portfolio}
						/>
						<GuardedRoute
							key="projects"
							path={PROJECTS_ROUTE}
							component={Projects}
						/>
						<GuardedRoute
							key="project"
							exact
							path={PROJECT_ROUTE}
							component={Project}
						/>
						<GuardedRoute
							key="profile"
							path={PROFILE_ROUTE}
							component={Profile}
						/>
						<GuardedRoute
							key="project-drawing-spec"
							path={PROJECT_DRAWINGS_SPEC_ROUTE}
							component={DrawingsSpecs}
						/>
						<GuardedRoute
							key="project-budget"
							path={PROJECT_BUDGET_SPEC_ROUTE}
							component={Budget}
						/>
						<GuardedRoute
							key="project-documents"
							path={PROJECT_DOCUMENTS_ROUTE}
							component={ProjectDocuments}
						/>
						<GuardedRoute
							key="project-settings"
							path={PROJECT_SETTINGS_ROUTE}
							component={ProjectSettings}
						/>
						<GuardedRoute
							key="portfolio-settings"
							path={PORTFOLIO_SETTINGS_ROUTE}
							component={PortfolioSettings}
						/>

						<GuardedRoute
							key="manage-project-team"
							path={PROJECT_TEAM_ROUTE}
							component={ManageProjectTeam}
						/>

						<GuardedRoute
							key="field-management"
							path={FIELD_MANAGEMENT_ROUTE}
							component={FieldManagement}
						/>
						<GuardedRoute
							key="my-assignments"
							path={MY_ASSIGNMENTS_ROUTE}
							component={MyAssignments}
						/>
						<GuardedRoute
							key="project-assignments"
							path={PROJECT_ASSIGNMENTS_ROUTE}
							component={ProjectAssignments}
						/>
						<GuardedRoute
							key="project-milestones"
							path={MILESTONES_ROUTE}
							component={Milestones}
						/>

						<GuardedRoute
							key="project-assignment"
							path={PROJECT_ASSIGNMENT_ROUTE}
							component={ProjectAssignment}
						/>

						<GuardedRoute
							key="checklistTemplates"
							path={CHECKLIST_TEMPLATES_ROUTE}
							component={ChecklistTemplates}
						/>
						<GuardedRoute
							key="manage-org-team-pos"
							path={ORGANIZATION_TEAM_POS_ROUTE}
							component={ManageOrgTeamPositions}
						/>
						<GuardedRoute
							key="manage-org-team"
							path={ORGANIZATION_TEAM_ROUTE}
							component={ManageOrganizationTeam}
						/>
						<GuardedRoute
							key="manage-org-seats"
							path={ORGANIZATION_SEATS_ROUTE}
							component={ManageOrganizationSeats}
						/>

						<GuardedRoute
							key="checklists"
							path={CHECKLISTS_ROUTE}
							component={Checklists}
						/>

						<GuardedRoute
							key="checklist"
							path={CHECKLIST_ROUTE}
							component={Checklist}
						/>

						<GuardedRoute
							key="daily-report"
							path={DAILY_REPORT_ROUTE}
							component={DailyReport}
						/>
						<GuardedRoute
							key="daily-reports"
							path={DAILY_REPORTS_ROUTE}
							component={DailyReports}
						/>
						<GuardedRoute
							key="field-work-directives"
							path={FIELD_WORK_DIRECTIVES_ROUTE}
							component={FieldWorkDirectives}
						/>
						<GuardedRoute
							key="notices-comply"
							path={NOTICES_ROUTE}
							component={NoticesToComply}
						/>
						<GuardedRoute
							key="punch-items"
							path={PUNCH_ITEMS_ROUTE}
							component={PunchItems}
						/>
						<GuardedRoute
							key="punch-item"
							path={PUNCH_ITEM_ROUTE}
							component={PunchItem}
						/>
						<GuardedRoute
							key="safety-notices"
							path={SAFETY_NOTICES_ROUTE}
							component={SafetyNotices}
						/>
						<GuardedRoute
							key="rfi"
							path={RFI_ROUTE}
							component={RequestForInfo}
						/>

						<GuardedRoute
							key="rfis"
							path={RFIS_ROUTE}
							component={RequestForInfos}
						/>
						<GuardedRoute
							key="my-projects"
							path={MY_PROJECTS_ROUTE}
							component={MyProjects}
						/>
						<GuardedRoute key="boards" path={BOARDS_ROUTE} component={Boards} />
						<GuardedRoute key="board" path={BOARD_ROUTE} component={Board} />
					</Suspense>
				</AnimatePresence>
			</GuardProvider>
		</IonRouterOutlet>
	);
};

export default Routes;
